<template>
  <div class="zone">
    <TJDetailTitle :titleData="'用量统计_省份'" />
    <TJDetailMenu :dataList="dataList" />

    <div class="diagram" v-loading.lock="fullscreenLoading">
      <p class="title">人份数构成分析图</p>
      <GoCompositionEchart
        :id="'echartA'"
        :ehartData="ehartDataAX"
        :colorList="colorList"
        :total="totalA"
        :dw="'人份'"
      ></GoCompositionEchart>

      <div class="oneTitle">
        <div class="radian">
          <div class="conronone">
            <div
              class="particulars"
              v-for="(item, index) of ehartDataA"
              :key="index"
            >
              <div class="bd" :style="{ background: colorList[index] }"></div>
              <div class="particularsleftone">{{ item.name }}</div>
              <div class="particularsCenter">{{ $tool.moneyFormatter(item.value,0) }}人份</div>
              <div class="particularslefttwo">
                {{ ((item.value / totalA) * 100).toFixed(2) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/tongji'
import GoCompositionEchart from '@/components/GoCompositionEchart'
import TJDetailMenu from '@/components/TJDetailMenu'
import TJDetailTitle from '@/components/TJDetailTitle'

export default {
  name: 'TimeSeries',
  components: { GoCompositionEchart, TJDetailMenu, TJDetailTitle },
  data() {
    return {
      fullscreenLoading: false, //加载中
      dataList: [
        {
          name: '省份',
          value: '',
          width: '50%',
          bottomBorder: false,
        },

        {
          name: '时间',
          value: '',
          status: '',
          width: '50%',
          bottomBorder: false,
        },
      ],

      query: {
        platform_id: '', //平台标识
        province_id: '', //省份标识

        start_stats_year: '', //开始年份 例如:2021
        end_stats_year: '', //结束年份
        start_stats_month: '', //开始月份 例如:202002
        end_stats_month: '', //结束月份
        start_stats_day: '', //开始日期 例如:20210304
        end_stats_day: '', //结束日期
        date_type: '', //日期类型:0:年,1,月,2,日
      },
      colorList: [
        '#166DC9',
        '#00AFE9',
        '#1ACB64',
        '#F77490',
        '#F9C942',
        '#2C52F1',
      ],
      ehartDataA: [],
      ehartDataAX: [],
      totalA: 0,
    }
  },
  created() {
    this.query = this.$route.query
    this.dataList[1].status = this.query.date_type
  },
  mounted() {
    this.province_used_day_constituteFun()
  },
  methods: {
    // 省份销量统计构成分析
    province_used_day_constituteFun() {
      this.fullscreenLoading = true
      let param = {
        province_id: this.query.province_id, //省份标识
        platform_id: this.query.platform_id, //平台标识
        // date_type: this.query.date_type, //日期类型:0:年,1,月,2,日
      }
      if (this.query.date_type == '年') {
        param.start_stats_year = this.query.start_stats_year
        param.end_stats_year = this.query.end_stats_year
        
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_year,
          this.query.end_stats_year,
        )
      } else if (this.query.date_type == '月') {
        param.start_stats_month = this.query.start_stats_month
        param.end_stats_month = this.query.end_stats_month
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_month,
          this.query.end_stats_month,
        )
      } else if (this.query.date_type == '日') {
        param.start_stats_day = this.query.start_stats_day
        param.end_stats_day = this.query.end_stats_day
        this.dataList[1].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_day,
          this.query.end_stats_day,
        )
      }
      api
        .province_used_day_constitute(param)
        .then((res) => {
          this.dataList[0].value = res.data.province_name
          let component = res.data.data
          this.ehartDataA = component.map((element) => {
            return {
                name: element.testing_unique,
                value: element.cnt,
            }
          })
          this.totalA = res.data.total

          //  少于5%归结到其他类中
          let otherItemVal = 0;
          this.ehartDataA.forEach(item => {
              if(item.value / this.totalA>=0.05){
                this.ehartDataAX.push(item);
                return;
              }
              otherItemVal += item.value
          })
          otherItemVal!=0&&this.ehartDataAX.push({
            name: '其他',
            value: otherItemVal,
          })
          

          this.fullscreenLoading = false
        })
        .catch((err) => {
          this.fullscreenLoading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .TJDetailMenu .box p:nth-child(2) {
  font-weight: bold;
}
.diagram {
  height: px(837);
  background-color: #fff;
  padding: 15px 0 0 0;
  ::v-deep .TJDetailMenu .box p:nth-child(2) {
    font-weight: bold;
  }
  .title {
    margin-left: 15px;
    font-weight: bold;
    color: #2d354c;
  }
}
.oneTitle {
  display: flex;
  align-items: center;
  margin-top: px(20);
  .radian {
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    border-top: 1px solid #ebf0f6;
  }
}
.conronone {
  background-color: #fff;
  padding: 0 px(8);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .particulars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: px(61);
    width: 100%;
    border-bottom: 1px solid #ebf0f6;
    box-sizing: border-box;
    .bd {
      margin-left: 40px;
      width: px(10);
      height: px(10);
      border-radius: 8px;
      margin-right: 8px;
    }
    .particularsleftone {
      min-width: 28%;
      color: #333333;
    }
    .particularsCenter {
      font-size: px(16);
      width: 35%;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .particularslefttwo {
      font-weight: bold;
      color: #333333;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-right: px(15);
    }
  }
  .particulars:nth-child(odd) {
    border-right: 1px solid #ebf0f6;
    box-sizing: border-box;
  }
}
</style>
